.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

#root {
  overflow: hidden;
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently*/
}

.logo {
  float: left;
  width: 120px;
  height: 31px;
  margin: 16px 24px 16px 0;
  background: rgba(255, 255, 255, 0.3);
}

.layout-toolbar .ant-menu {
  background: #1f1f1f;
}
.layout-toolbar .ant-menu-inline-collapsed {
  width: 60px;
  background: #1f1f1f;
}

.ant-list-split .ant-list-item:last-child {
  border-bottom: 1px solid #303030;
}

.ant-layout-sider-children {
  height: calc(100vh - 105px);
  overflow-y: hidden;
  background: #141414;
}

/*
 * Color picker bug fix
 */

.saturation-white {
  pointer-events: none;
}
.hue-horizontal div div {
  pointer-events: none;
}

.simplebar-scrollbar::before {
  background-color: white !important;
}

.ant-layout {
  background: #141414 !important;
}

/*
 * Dashboard Radio Buttons
 */

.dashboard-add-layout-radio .ant-radio .ant-radio-inner {
  margin: auto;
}
.dashboard-add-layout-radio .ant-radio {
  position: absolute;
  width: 100%;
}

.dashboard-add-layout-radio .ant-radio-checked::after {
  width: 14px;
  height: 14px !important;
  margin: auto !important;
  left: 47%;
}

/* .dashboard-add-layout-radio .ant-radio-checked .ant-radio-inner::after {
  width: 100%;
  height: 14px !important;
  margin: auto !important;
} */
.dashboard-add-layout-radio {
  justify-content: center;
  margin-top: 30px;
}

.dashboard-add-layout-radio-group {
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  flex-direction: row;
  width: 100%;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.zoom-number .ant-input-number-handler-wrap {
  display: none !important;
}

.zoom-number .ant-input-number-input-wrap input {
  text-align: center;
}

.white {
  color: #fff !important;
}

.add-layout-container
  .ant-drawer-content-wrapper
  .ant-drawer-content
  .ant-drawer-wrapper-body
  .ant-drawer-header {
  border-bottom: 1px red solid;
}
