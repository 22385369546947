body {
  margin: 0;
  font-family: "myriad-pro", -apple-system, BlinkMacSystemFont, "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif !important;
  /* font-weight: normal; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
  margin: 0;
}

.fluid-width-video-container {
  flex-grow: 1;
  width: 100%;
}
.fluid-width-video-wrapper {
  width: 100%;
  position: relative;
  padding: 0;
}

.fluid-width-video-wrapper iframe,
.fluid-width-video-wrapper object,
.fluid-width-video-wrapper embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
